<template>
  <div class="welcome_page custom_scrollbar background_1">

    <div class="contacts">
      <div class="contacts_item" style="display: none;">
        <div class="icon callIcon"></div>
        <div class="text">+ 7 (800) 555-55-55</div>
      </div>
      <div class="contacts_item" style="display: none;">
        <div class="icon emeilIcon"></div>
        <div class="text">info@bingosoft.ru</div>
      </div>
    </div>

    <div class="main">
      <div class="logo_teplo" style="display: none;"></div>
      <div class="title">
        Автоматизированная система выполнения заявок потребителей
      </div>

      <div class="document">
        <div class="docs_column">
          <div class="doc_item">
            <a href="/homepage/empty_file.docx" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Инструкция по работе в системе</div>
            </a>
          </div>
          <div class="doc_item" style="display: none;">
            <a href="/homepage/Pochta/Формирование инвестзаявок.docx" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Инструкция по формированию заявок</div>
            </a>
          </div>
        </div>
        <div class="docs_column" style="display: none;">
          <div class="doc_item">
            <a href="/homepage/Pochta/Руководство по формированию корректировки проекта.docx" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Руководство по формированию корректировки проекта</div>
            </a>
          </div>
          <div class="doc_item" style="display: none;">
            <a href="/homepage/empty_file.docx" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Руководство по формированию отчетности</div>
            </a>
          </div>
        </div>
      </div>

    </div>

    <div class="footer">
      <!-- <div class="footer_gerb">
        <div class="gerb"></div>
      </div> -->
      <div class="footer_container">
        <div class="logo_accent"></div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'WelcomePage'
}
</script>

<style scoped src="./Welcome.css">
</style>
